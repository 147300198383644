import React from 'react';
import SectionWrapper from '../../UI/SectionWrapper';
import TitleSection from "../../UI/TitleSection";
import Accordion from "../../UI/Accordion";
import styled from "styled-components";

const AccordionWrapper = styled.div`
  width: 70%;
  //margin-left: auto; /* Прижатие к правому краю */
  
    @media (max-width: 992px) {
        width: 100%;
    }

`;

const StackSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

const SoftSkillsContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
    @media (max-width: 992px) {
        display: none;
    }
`;

const TitleSkillSSection = styled.h3`
  font-size: 20px;
  font-family: 'Inter-Bold';
  color: #151518;
  font-weight: 700;
  margin: 0 0 10px;
`;

const SkillsContent = styled.div`
  font-size: 20px;
  font-family: 'Inter-Regular';
  color: #151518;
  margin-top: 0;
  

  & > li {
    margin: 0;
    padding: 0;
  }
  
  & > ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  li {
    margin: 0;
    padding: 0 0 5px;
  }
  
  
`;

const JobExperience = () => {

    const items = [
        {
            title: 'Adbis',
            content: `<h2>Responsibilities:</h2>
    <ul>
        <li>Conducting consultations with clients: This involves meeting and communicating with clients to understand their needs, project goals, and design expectations.</li>
        <li>Providing competitor analysis: Studying client competitors, their designs, functionalities, and strategies to identify strengths, weaknesses, and opportunities for improvement.</li>
        <li>Creating user flows: Visualizing how users will interact with the product from start to finish to optimize the user experience.</li>
        <li>Crafting Lo-Fi and Hi-Fi wireframes: Creating low-fidelity (Lo-Fi) and high-fidelity (Hi-Fi) wireframes to visualize design concepts and product functionalities.</li>
        <li>Designing banners for the website: Creating graphical elements and banners to enhance the visual appeal of the website and attract user attention.</li>
        <li>Building interactive prototypes: Developing interactive product models that allow clients and stakeholders to test functionality and interaction.</li>
        <li>Doing adaptive design: Creating designs optimized for viewing on various devices and screens to ensure a seamless and user-friendly experience.</li>
        <li>Creating UI Kits: Developing sets of graphical elements used to support a unified style and consistency in user interface design.</li>
        <li>Performing a complete website redesign to enhance user experience: Analyzing and redesigning the entire website to optimize the user experience and improve its effectiveness.</li>
        <li>Creating inclusive design: Considering user diversity and needs in design to ensure accessibility and usability for all.</li>
        <li>Presenting designs: Showcasing designs to clients and stakeholders for feedback and approval.</li>
        <li>Consistently attending client conference calls: Participating in conference calls to discuss project progress, address issues, and receive feedback from clients.</li>
        <li>Discussing new features with management and stakeholders: Communicating with management and stakeholders to identify new features and product improvements based on feedback and requirements.</li>
        <li>Creating style guides for front-end developers: Developing documentation that defines styles, fonts, colors, and other design aspects to be implemented by developers.</li>
        <li>Collaborating with developers at all stages of development: Actively engaging with developers to ensure design compliance with technical requirements and successful implementation of concepts.</li>
    </ul>`
        },
        {
            title: 'Freelance | Photo editing',
            content: `<h2>Responsibilities:</h2>
    <ul>
        <li>Photo editing</li>
        <li>Photo retouching</li>
        <li>Creating mockups</li>
        <li>Preparing presentations for commercial proposals (CP)</li>
    </ul>`,
        },
    ];

    const skills = [
        {
            title: 'Acquired skills',
            content: `<ul><li>Competitor analysis</li><li>User Flow</li><li>Wireframing</li><li>Prototyping</li><li>Moodboards</li><li>Interaction Design</li><li>Information Architecture</li><li>Adaptive Design</li><li>Inclusive design</li></ul>`
        }
    ];

    return (
        <SectionWrapper>
            <TitleSection title="Job Experience"/>
            <StackSection>
                <SoftSkillsContainer>
                   <TitleSkillSSection>{skills[0].title}</TitleSkillSSection>
                    <SkillsContent dangerouslySetInnerHTML={{__html: skills[0].content}}/>

                </SoftSkillsContainer>
                <AccordionWrapper>
                    <Accordion items={items}/>
                </AccordionWrapper>
            </StackSection>
        </SectionWrapper>
    );
};

export default JobExperience;
import React from 'react';
import RoundIconButton from "../../UI/RoundButton";
import SectionWrapper from "../../UI/SectionWrapper";
import styled from "styled-components";
import ReusableButton from "../../UI/StyledButton";
import QRImage from '../../../assets/Images/QR.svg';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  
    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const FirstBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  flex-direction: column;
  gap: 20px;
`;

const SecondBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  
    @media (max-width: 992px) {
        height: 400px;
      align-items: center;
    }
`;

const CardWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > * {
    position: absolute;
    width: 150px;
    height: 100px;
    background-color: #ccc;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    
    @media (max-width: 992px) {
        width: 100px;
        height: 70px;
    }
  }

  & > :nth-child(1) { /* Первая карточка ++ */ 
    background-image: url('https://i.ibb.co/M647x8k/1.jpg');
    top: -200px;
    background-size: cover;
    background-position: center;
    left: -200px;
    
    @media (max-width: 992px) {
        top: -150px;
        left: -125px;
    }
  }

  & > :nth-child(2) { /* Вторая карточка ++*/
    background-image: url('https://i.ibb.co/8Njx7fs/1.jpg');
    top: -200px;
    background-size: cover;
    background-position: center;
    right: -200px;
    
    @media (max-width: 992px) {
        top: -150px;
        right: -125px;
    }

  }

  & > :nth-child(3) { /* Третья карточка ++ */
    background-image: url('https://i.ibb.co/FsGFz3x/2.jpg');
    top: -140px;
    background-size: cover;
    background-position: center;
    right: -250px;
    
    @media (max-width: 992px) {
        top: -100px;
        right: -175px;
    }
  }

  & > :nth-child(4) { /* Четвертая карточка */
    bottom: -200px;
    background-image: url('https://i.ibb.co/27DCF0s/2024-05-06-23-25-02.png');
    right: -200px;
    background-size: cover;
    background-position: center;
    @media (max-width: 992px) {
        bottom: -150px;
        right: -125px;
    }
  }

  & > :nth-child(5) { /* Пятая карточка */
    bottom: -140px;
    background-image: url('https://i.ibb.co/bznrV93/2024-05-06-23-24-52.png');
    background-size: cover;
    background-position: center;
    right: -250px;
    
    @media (max-width: 992px) {
        bottom: -100px;
        right: -175px;
    }
  }

  & > :nth-child(6) { /* Шестая карточка ++*/
    top: -140px;
    background-image: url('https://i.ibb.co/9hpb51h/2.jpg');
    left: -250px;
    background-size: cover;
    background-position: center;
    
    @media (max-width: 992px) {
        top: -100px;
        left: -175px;
    }
  }

  & > :nth-child(7) { /* Седьмая карточка */
    bottom: -200px;
    left: -200px;
    background-size: cover;
    background-position: center;
    background-image: url('https://i.ibb.co/qrr1tfw/2024-05-06-23-21-16.png');
    
    @media (max-width: 992px) {
        bottom: -150px;
        left: -125px;
    }
  }

  & > :nth-child(8) { /* Восьмая карточка */
    bottom: -140px;
    background-image: url('https://i.ibb.co/dG7g8Kg/2024-05-06-23-20-53.png');
    left: -250px;
    background-size: cover;
    background-position: center;
    
    @media (max-width: 992px) {
        bottom: -100px;
        left: -175px;
    }
  }
  
`;

const Badge = styled.div`
  background-color: #00A3FF;
  border-radius: 10px;
  padding: 10px;
  font-family: 'Inter-Regular';
  font-size: 20px;
  color: #fff;
  width: fit-content;
  
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

const Title = styled.div`
  font-family: 'Inter-Bold';
  font-weight: 700;
  font-size: 60px;
  color: #151518;
  
    @media (max-width: 992px) {
        font-size: 20px;
    }
`;

const ShortDescription = styled.div`
  font-family: 'Inter-Regular';
  font-size: 20px;
  color: #151518;
  width: 100%;
  z-index: 10;
  
    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //box-shadow: 0 0 100px 0 #00A3FF;
`;

const QR = styled.div`
  width: 190px;
  height: 190px;
  background-image: url(${QRImage});
  border-radius: 10px;
    background-size: cover;
    background-position: center;
`;

const InfoSectionWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  
    @media (max-width: 992px) {
        display: none;
    }
`;


const FirstScreen = () => {

    return (
        <SectionWrapper paddingTop="50px">
            <Root>
                <FirstBlock>
                    <Badge>Middle</Badge>
                    <div>
                        <Title>UX / UI Designer</Title>
                    </div>
                    <ShortDescription>
                        I am a designer with 1 year of experience in web design (from client briefing to creating style guides for front-end developers, and I have consistently stayed in touch with them until the project's completion). I prefer to work on complex projects in a team environment as it allows me to expand my knowledge and hear different points of view.
                    </ShortDescription>
                    <InfoSectionWrapper>
                        <QR/>
                        <ButtonWrapper>
                            <ReusableButton linkTo="https://drive.google.com/file/d/1vzInySxb3BquuX2P0md0Wvh8OXM6BPNT/view?usp=sharing" openPdfInNewTab={true} label="Download CV" variant="outlined"/>
                            <ReusableButton linkTo="https://www.linkedin.com/in/mykyta-yarosh-46365b277/" openPdfInNewTab={true} label="LinkedIn" variant="outlined"/>
                            <ReusableButton as={'a'} href="mailto:nikita.rososh.a@gmail.com" label="E-Mail" variant="outlined"/>

                        </ButtonWrapper>
                    </InfoSectionWrapper>
                </FirstBlock>
                <SecondBlock>
                    <CardWrapper>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </CardWrapper>
                    <RoundIconButton/>
                </SecondBlock>
            </Root>
        </SectionWrapper>
    );
};

export default FirstScreen;
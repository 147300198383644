import React, { useState } from 'react';
import styled from 'styled-components';
import LinkedInIcon from '../../../assets/Icons/linked-icon.svg';
import TelegramIcon from '../../../assets/Icons/tg-icon.svg';
import ResumeIcon from '../../../assets/Icons/resume-icon.svg';
import CloseIcon from '../../../assets/Icons/close-icon.svg';
import GMailIcon from '../../../assets/Icons/gmail-icon.svg';

// Стилизованный компонент для плавающей кнопки
const FloatingButtonWrapper = styled.button`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    -webkit-tap-highlight-color: transparent;
    background-color: #00A3FF;
    z-index: 1400;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

// Стилизованный компонент для модального окна
const ModalWrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 20px 85px 0;
`;


// Стилизованный компонент для социальных кнопок
const SocialButton = styled.button`
  padding: 8px 16px;
  font-family: Inter-Regular;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #00A3FF;
  border-radius: 4px;
  text-transform: none;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  outline: none;
  color: #353535;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 163, 255, 0.5);
    color: #00A3FF;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 14px;
  }
`;

// Стилизованный компонент для иконки "трех точек" (Three Dots Icon)
const ThreeDotsIcon = styled.div`
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  margin: 2px;
`;

// Стилизованный компонент для контейнера
const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #00A3FF;
`;

// Стилизованный компонент для корневого контейнера
const Root = styled.div`
    z-index: 1300;
`;

const FloatingButton = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Root>
            <FloatingButtonWrapper onClick={open ? handleClose : handleOpen}>
                {open ? (
                    <img src={CloseIcon} alt="Close Icon" style={{ width: '24px', height: '24px' }} />
                ) : (
                    <>
                        <ThreeDotsIcon />
                        <ThreeDotsIcon />
                        <ThreeDotsIcon />
                    </>
                )}
            </FloatingButtonWrapper>
            {open && (
                <ModalWrapper>
                    <RootContainer>
                        <SocialButton onClick={() => window.open('https://drive.google.com/file/d/1vzInySxb3BquuX2P0md0Wvh8OXM6BPNT/view?usp=sharing', '_blank')}>
                            <img src={ResumeIcon} alt="Resume Icon" style={{ width: '16px', height: '16px' }} />
                            Resume
                        </SocialButton>
                        <SocialButton onClick={() => window.open('https://t.me/mykytayarosh', '_blank')}>
                            <img src={TelegramIcon} alt="Telegram Icon" style={{ width: '16px', height: '16px' }} />
                            Telegram
                        </SocialButton>
                        <SocialButton onClick={() => window.open('https://www.linkedin.com/in/mykyta-yarosh-46365b277/', '_blank')}>
                            <img src={LinkedInIcon} alt="LinkedIn Icon" style={{ width: '16px', height: '16px' }} />
                            LinkedIn
                        </SocialButton>
                        <SocialButton as={'a'} href="mailto:nikita.rososh.a@gmail.com">
                            <img src={GMailIcon} alt="Gmail Icon" style={{ width: '16px', height: '16px' }} />
                            E-Mail
                        </SocialButton>
                    </RootContainer>
                </ModalWrapper>
            )}
        </Root>
    );
};

export default FloatingButton;


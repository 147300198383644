import React from 'react';
import SectionWrapper from '../../UI/SectionWrapper';
import TitleSection from "../../UI/TitleSection";
import styled from "styled-components";
import Tag from "../../UI/Tag";
import TurystykaDesktop from "../../../assets/MockUps/turystyka-desktop.png";
import FundacjaDesktop from "../../../assets/MockUps/fundacja-redesign.png";
import WislaDesktop from "../../../assets/MockUps/wisla-mockup.png";
import ArrowButton from "../../UI/ArrowButton";
import BSMDesktop from "../../../assets/MockUps/bsm-mockup.png";
import PetsDesktop from "../../../assets/MockUps/pets-mockup.png";
import RoamWellDesktop from "../../../assets/MockUps/roamwell-mockup.png";
import StulDesktop from "../../../assets/MockUps/stul-mockup.png";

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Два элемента в ряду */
  gap: 40px; /* Промежуток между элементами */
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr; /* Один элемент в ряду */
  }
`;

const ProjectItem = styled.div`
  position: relative; /* Позиционирование для контейнера проекта */
  //margin-bottom: 20px;
  border-radius: 10px;
  padding: 32px 20px 20px 20px;
  background-color: ${({backgroundColor}) => backgroundColor}; /* Индивидуальный цвет фона */
  
  @media (max-width: 992px) {
    padding: 10px;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 50px;
  
    @media (max-width: 992px) {
        margin-bottom: 20px;
    }
`;

const ProjectTags = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const ProjectDescription = styled.p`
  margin-top: 10px;
  font-size: 20px;
  font-family: 'Inter-Regular';
  color: white;
  
    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center; /* Выравнивание изображений по центру */
`;

const Portfolio = () => {

    const projects = [
        {
            id: 1,
            image: TurystykaDesktop,
            tags: ['Travel site', 'Blog', 'Redesign', 'WCAG'],
            description: '"Turystyka BEZ FILTRÓW" is a brand that started as a hobbyist travel blog, with time growing into a significant initiative on the scale of Bydgoszcz and the region. Today, it includes a website with regular articles on tourism and culture (including series: "Bydgoszcz estates WITHOUT FILTERS", "Bydgoszcz through the keyhole")',
            link: 'https://turystykabezfiltrow.com/',
            backgroundColor: '#9AB6FFCC',
            buttonText: 'Visit site',
        },
        {
            id: 2,
            image: FundacjaDesktop,
            tags: ['Blog', 'Redesign', 'WCAG'],
            description: 'The Foundation for the Promotion of Culture and Tourism "By the River" is a non-governmental organization, registered on 31.07.2020. The foundation is formed by a three-member international team of funders, at the same time making up its Board of Directors. They are: Piotr Weckwerth (president), and Serhii Zinchenko and Anton Karabach (vice-presidents). Inna Yaremchuk works with the Foundation on a permanent basis.\n',
            link: 'https://fundacjanadrzeka.com/',
            backgroundColor: '#78BFCECC',
            buttonText: 'Visit site'

        },
        {
            id: 3,
            image: BSMDesktop,
            tags: ['Information site'],
            description: 'Medical School in Bydgoszcz is a non-governmental educational institution officially recognized as a public school. This respected institution was previously known as CED Medical School in Bydgoszcz. Since February 1, 2014, it has become part of the register of non-governmental schools and institutions registered by the President of the City of Bydgoszcz.\n',
            link: 'https://www.behance.net/gallery/178488743/Bydgoska-Szkola-Medyczna-Redesign',
            backgroundColor: '#DAC590',
            buttonText: 'Visit',
            behanceLogo: true
        },
        {
            id: 4,
            image: PetsDesktop,
            tags: ['Mobile app'],
            description: 'PetsHere is an inspiring application designed for those seeking the perfect furry companion. Whether you\'re looking to adopt a shelter pet, purchase a pet from a breeder, or simply learn more about a specific breed, PetsHere is your reliable partner on the path to understanding and enjoying a joyful life together.',
            link: 'https://www.behance.net/gallery/177886521/PetsHere-Mobile-App-Design',
            backgroundColor: '#B2CBFF',
            buttonText: 'Visit',
            behanceLogo: true
        },
        {
            id: 5,
            image: RoamWellDesktop,
            tags: ['Rental', 'Booking'],
            description: 'The ultimate goal of the website is to attract potential customers to book tours and travel through agency. The main idea of the site is to create an attractive, informative, and persuasive interface that will entice potential customers to book tours and travel.',
            link: 'https://www.behance.net/gallery/168043529/Roamwell-Travel-agency',
            backgroundColor: '#BFAB97',
            buttonText: 'Visit',
            behanceLogo: true,
        },
        {
            id: 6,
            image: StulDesktop,
            tags: ['E-commerce'],
            description: 'My task was to design the site, showing the use of natural materials, to accentuate the style of furniture, to make a simple and functional for the user to the products, the production and the team.\n' +
                'It’s a website witch is designed for a very special furniture studio. They use only natural metals in their production, they have own style and own vision.',
            link: 'https://www.behance.net/gallery/162915941/Furniture-store-E-Commerce-web-design',
            backgroundColor: '#96838A',
            buttonText: 'Visit',
            behanceLogo: true
        },
        {
            id: 7,
            image: WislaDesktop,
            tags: ['Landing-Page', 'Project Site'],
            description: 'The main goal of this project was to demonstrate the severity of the drought\'s impact on the farming community of Kujawa and to propose potential solutions to address this issue.\n' +
                'The project was collaboratively prepared by an independent team of experts from various industries and institutions, appointed by the Nadwislańskie Związek Pracodawców Lewiatan.',
            link: 'https://wisladlakujaw.pl/',
            backgroundColor: '#63BD7F',
            buttonText: 'Visit site'
        },
    ];

    return (
        <SectionWrapper id="portfolio" >
            <TitleSection title="Portfolio"/>
            <ProjectsGrid>
                {projects.map(project => (
                    <ProjectItem key={project.id} backgroundColor={project.backgroundColor}>
                        <ImageWrapper>
                            <ProjectImage src={project.image} alt={`Project ${project.id}`}/>
                        </ImageWrapper>
                        <ProjectTags>
                            {project.tags.map(tag => (
                                <Tag key={tag} title={tag}/>
                            ))}
                        </ProjectTags>
                        <ProjectDescription>{project.description}</ProjectDescription>
                        <ArrowButton title={project.buttonText} to={project.link} whiteArrow={true} behanceLogo={project.behanceLogo}/>
                    </ProjectItem>
                ))}
            </ProjectsGrid>
        </SectionWrapper>
    );
};

export default Portfolio;


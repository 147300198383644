import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowIcon from '../../../assets/Icons/arrow-accordion.svg'

const AccordionWrapper = styled.div`
  width: 100%;
  -webkit-tap-highlight-color: transparent;
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #151518;
`;

const AccordionHeader = styled.div`
  display: flex;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 0;
  cursor: pointer;
  
  @media (max-width: 992px) {
    padding: 10px 0;
  }
`;

const AccordionTitle = styled.h2`
  margin: 0;
    font-size: 32px;
  font-family: 'Inter-Bold';
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s ease; /* Добавляем плавную анимацию для заголовка */
  color: ${({ isOpen }) => (isOpen ? '#00A3FF' : '#151518')};
  
  &:hover {
    color: #00A3FF;
  }
  
    @media (max-width: 992px) {
        font-size: 18px;
`;

const AccordionIcon = styled.img`
  width: 20px;
  height: 20px;
  -webkit-tap-highlight-color: transparent;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease; /* Добавляем плавную анимацию для иконки */
  
`;

const AccordionContent = styled.div`
  margin-bottom: ${({ isOpen }) => (isOpen ? '20px' : '0')};
  font-family: 'Inter-Regular';
  color: #151518;
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0')}; /* Изменяем максимальную высоту */
  overflow: hidden; /* Скрываем содержимое, выходящее за пределы максимальной высоты */
  transition: max-height 0.5s ease, margin-bottom 0.5s ease; /* Добавляем плавную анимацию для контента */

  h2 {
    font-size: 20px;
    font-family: 'Inter-Bold';
    color: #151518;
    font-weight: 700;
    margin: 0 0 10px;
    
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
  
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;


const HR = styled.hr`
  border: 1px solid #151518;
  margin: 0;
`;

const Accordion = ({ items }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <AccordionWrapper>
            {items.map((item, index) => (
                <AccordionItem key={index}>
                    {index === 0 && <HR />}
                    <AccordionHeader onClick={() => toggleAccordion(index)} isOpen={openIndex === index}>
                        <AccordionTitle isOpen={openIndex === index}>{item.title}</AccordionTitle>
                        <AccordionIcon src={ArrowIcon} alt="Arrow Icon" isOpen={openIndex === index} />
                    </AccordionHeader>
                    <AccordionContent isOpen={openIndex === index} dangerouslySetInnerHTML={{ __html: item.content }} />
                </AccordionItem>
            ))}
        </AccordionWrapper>
    );
};

export default Accordion;


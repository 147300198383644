import React from 'react';
import SectionWrapper from "../../UI/SectionWrapper";
import styled from "styled-components";
import ReusableButton from "../../UI/StyledButton";
import QRImage from '../../../assets/Images/QR.svg';
import TitleSection from "../../UI/TitleSection";


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const QR = styled.div`
  width: 190px;
  height: 190px;
  background-image: url(${QRImage});
  border-radius: 10px;
  background-size: cover;
  background-position: center;
`;

const InfoSectionWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  width: 100%;
`;


const Footer = () => {
    return (
        <SectionWrapper paddingBottom="100px">
            <TitleSection title="Contacts"/>

            <InfoSectionWrapper>
                <QR/>
                <ButtonWrapper>
                    <ReusableButton linkTo="https://drive.google.com/file/d/1vzInySxb3BquuX2P0md0Wvh8OXM6BPNT/view?usp=sharing" openPdfInNewTab={true}
                                    label="Download CV" variant="outlined"/>
                    <ReusableButton linkTo="https://www.linkedin.com/in/mykyta-yarosh-46365b277/" openPdfInNewTab={true} label="LinkedIn"
                                    variant="outlined"/>
                    <ReusableButton as={"a"} href="mailto:nikita.rososh.a@gmail.com" label="E-Mail" variant="outlined"/>

                </ButtonWrapper>
            </InfoSectionWrapper>
        </SectionWrapper>
    );
};

export default Footer;
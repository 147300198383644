import React from 'react';
import ReactDOM from 'react-dom';
import Header from "./components/Layout/Header";
import MainPage from "./components/MainPage";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Footer from "./components/Layout/Footer";
import {FloatingButtonProvider} from "./components/UI/FloatingButton/FloatingButtonContext";


const App = () => {

    return (
        <Router>
            <FloatingButtonProvider>
                <Header name="Yarosh" surname="Mykyta"/>
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                </Routes>
                <Footer/>
            </FloatingButtonProvider>

        </Router>
    );
}


ReactDOM.render(
    <>
        <App/>
    </>,
    document.getElementById('root')
);


import React, {useState} from 'react';
import SectionWrapper from '../../UI/SectionWrapper';
import TitleSection from "../../UI/TitleSection";
import styled from "styled-components";
import FigmaLogo from "../../../assets/StackLogo/Figma-logo.svg";
import PhotoshopLogo from "../../../assets/StackLogo/photoshop-logo.svg";
import AfterEffectsLogo from "../../../assets/StackLogo/AE-logo.svg";
import SlackLogo from "../../../assets/StackLogo/Slack-logo.svg";


const CardWrapper = styled.div`
  width: 70%;
  margin-left: auto; /* Прижатие к правому краю */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-content: flex-start;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const DescriptionSection = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.5s ease; /* Анимация плавного появления */

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const TitleTechnology = styled.h3`
  font-size: 32px;
  font-weight: 700;
  color: #151518;
  font-family: 'Inter-Bold', sans-serif;
  margin: 0;
  
    @media (max-width: 992px) {
        font-size: 18px;
    }
`;

const Description = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #151518;
  font-family: 'Inter-Regular', sans-serif;

    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

const CardWrapperBox = styled.div`
  width: 190px;
  height: 190px;
  border-radius: 10px;
  background-color: ${({selected}) => (selected ? '#00a3ff' : '#ECECEC')};
  
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: #00a3ff;
  }

  &:active {
    background-color: #00a3ff;
  }

  @media (max-width: 992px) {
    width: 80px;
    height: 80px;
  }
`;

const Image = styled.img`
  transition: all 0.5s ease;
  opacity: ${({selected}) => (selected ? 1 : 0.5)};
  filter: ${({selected}) => (selected ? 'none' : 'grayscale(100%)')};
  width: 100px;
  height: 100px;
  object-fit: contain;

  ${CardWrapperBox}:hover & {
    opacity: 1;
    filter: none;
  }


  @media (max-width: 992px) {
    width: 40px;
    height: 40px;
    filter: none;
    opacity: 1;
  }
`;

const ToolsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

const Stack = () => {
    const [selectedItem, setSelectedItem] = useState(0); // Выбираем первый элемент по умолчанию


    const handleCardClick = (index) => {
        setSelectedItem(index === selectedItem ? null : index);
    };

    const items = [
        {
            title: 'Figma & FigJam',
            img: FigmaLogo,
            desc:
                'Most of the time, I use Figma in my work, meaning I spend about 90% of my working hours there. I start with creating Lo-Fi and Hi-Fi wireframes and finish with prototyping and creating presentations for the client. Additionally, during the design process, I create reusable components so that I don\'t have to recreate every button or product card from scratch each time. This is probably the most convenient feature of Figma because it allows me to easily adjust sizes or colors across all reusable elements on the website.',
        },
        {
            title: 'Photoshop',
            img: PhotoshopLogo,
            desc: 'I mostly use Photoshop for editing photos because sometimes I need to remove unwanted objects from the photo. Additionally, I use it for creating mockups or working with them.',
        },
        {
            title: 'After Effects',
            img: AfterEffectsLogo,
            desc: 'I use After Effects only in cases when I need to clearly demonstrate to the frontend developer how elements should behave upon hovering, clicking, and so on.',
        },
        {
            title: 'Slack',
            img: SlackLogo,
            desc: 'I use Slack for communication with the team, as well as for sharing files and links. It is also convenient to use it for quick communication with the client.',
        },
    ];

    return (
        <SectionWrapper>
            <TitleSection title="Tools"/>
            <ToolsWrapper>
                <DescriptionSection visible={selectedItem !== null}>
                    {selectedItem !== null && (
                        <>
                            <TitleTechnology>{items[selectedItem].title}</TitleTechnology>
                            <Description>{items[selectedItem].desc}</Description>
                        </>
                    )}
                </DescriptionSection>
                <CardWrapper>
                    {items.map((item, index) => (
                        <CardWrapperBox
                            key={index}
                            onClick={() => handleCardClick(index)}
                            selected={selectedItem === index}
                        >
                            <Image src={item.img} alt={item.title} selected={selectedItem === index}/>
                        </CardWrapperBox>
                    ))}
                </CardWrapper>
            </ToolsWrapper>
        </SectionWrapper>
    );
};

export default Stack;